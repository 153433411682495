<template>
  <el-form :inline="true" style="width: 96%; margin-left: 2%;margin-top:20px">
    <el-form-item label="门店编号">
      <el-input
        placeholder="请输入门店编号"
        v-model="data.code"
        class="input-search-tel"
        clearable
        @keyup.enter="queryShops"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店名称">
      <el-input
        clearable
        placeholder="请输入门店名称"
        v-model="data.name"
        class="input-search-tel"
        @keyup.enter="queryShops"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        v-model="data.geoCode"
        :options="data.shopLists"
        :props="data.optionsProps"
        :show-all-levels="false"
        filterable
        clearable
        placeholder="选择门店区域"
        @change="queryShops"
      />
    </el-form-item>
    <el-form-item label="系统到期情况">
      <el-select v-model="data.expiredStatus" placeholder="请选择" clearable>
        <el-option :value="0" label="未知">未知</el-option>
        <el-option :value="1" label="未到期">未到期</el-option>
        <el-option :value="2" label="已到期">已到期</el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="queryShops" v-if="data.authMenus.query">查询</el-button>
      <el-button @click="resetQuery">重置</el-button>
      <el-button @click="addShop" v-if="data.authMenus.add">新增</el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" width="120" />
    <el-table-column prop="code" label="门店编号" align="center" width="120" />
    <el-table-column prop="name" label="门店名称" align="center" width="200" />
    <el-table-column prop="storeShortName" label="门店简介" align="center" width="200" />
    <el-table-column prop="characterTypeName" label="门店类型" align="center" width="120"> </el-table-column>
    <el-table-column show-overflow-tooltip label="门店区域" align="center" width="120">
      <template #default="scope">
        <span
          style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer;"
          :title="scope.row.storeAreaName"
        >
          {{ scope.row.storeAreaName }}</span
        >
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="门店地址" align="center" width="120">
      <template #default="scope">
        <span
          style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer;"
          :title="scope.row.address"
        >
          {{ scope.row.address }}</span
        >
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="testOperateTime" label="试运营开业日" align="center" width="120">
      <template #default="scope">
        {{ scope.row.testOperateTime?.split(' ')[0] || '' }}
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="openTime" label="全证开业日" align="center" width="120">
      <template #default="scope">
        {{ scope.row.openTime?.split(' ')[0] || '' }}
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="operateState" label="营业状态" align="center" width="120">
      <template #default="scope">
        <el-tag v-if="scope.row.operateState === 1">正常营业</el-tag>
        <el-tag v-else-if="scope.row.operateState === 0" type="error">暂停营业</el-tag>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="state" label="合作状态" align="center" width="120">
      <template #default="scope">
        <el-tag v-if="scope.row.state === 1">启用</el-tag>
        <el-tag v-else-if="scope.row.state === 0" type="error">停用</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="onlineTime" label="系统上线时间" align="center" width="120" />
    <el-table-column prop="expiredTime" label="系统到期时间" align="center" width="120" />
    <el-table-column label="系统到期情况" align="center" width="120">
      <template #default="scope">
        <el-tag v-if="scope.row.expiredStatus === 0" type="warning">未知</el-tag>
        <el-tag v-else-if="scope.row.expiredStatus === 1" type="success">未到期</el-tag>
        <el-tag v-else-if="scope.row.expiredStatus === 2" type="error">已到期</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="380" align="center" fixed="right">
      <template #default="scope">
        <el-button link type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.query">详情</el-button>
        <el-button link type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update">修改</el-button>
        <el-button link type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.update">{{
          scope.row.state === 1 ? '停用' : '启用'
        }}</el-button>
        <el-button link type="text" @click="showDetail(4, scope.row)" v-if="data.authMenus.billMsg">结算信息</el-button>
        <el-button link type="text" @click="showDetail(5, scope.row)" v-if="data.authMenus.payment">缴费登记</el-button>
        <el-button link type="text" @click="showDetail(6, scope.row)" v-if="data.authMenus.configuration"
          >配置管理</el-button
        >
        <el-button link type="text" @click="showDetail(7, scope.row)" v-if="!data.authMenus.qrcode">群二维码</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddShop
    :dialogVisible="data.dialogVisible"
    v-if="data.dialogVisible"
    :accountMsg="data.accountMsg"
    :isEdit="data.isEdit"
    @update-dialogVisible="updateDialogVisible"
  ></AddShop>
  <BillingMsg
    :dialogVisible="data.billMsgDialogVisible"
    v-if="data.billMsgDialogVisible"
    :accountMsg="data.accountMsg"
    @update-dialogVisible="updateBillMsgDialogVisible"
  ></BillingMsg>

  <PayMent
    :dialogVisible="data.payMentDialogVisible"
    v-if="data.payMentDialogVisible"
    :specRowMsg="data.accountMsg"
    @update-dialogVisible="updatePayMentDialogVisible"
  ></PayMent>

  <Configuration
    :dialogVisible="data.configurationVisible"
    v-if="data.configurationVisible"
    :specRowMsg="data.accountMsg"
    @update-dialogVisible="updateConfigurationVisible"
  ></Configuration>

  <Qrcode
    :dialogVisible="data.qrcodeVisible"
    v-if="data.qrcodeVisible"
    :specRowMsg="data.accountMsg"
    @update-dialogVisible="updateQrcodeVisible"
  >
  </Qrcode>
</template>

<script setup>
import commonApi from '@/api'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import { reactive, onMounted, toRaw, computed, ref } from 'vue'
import AddShop from './addShop/Index'
import BillingMsg from './billingMsg/Index.vue'
import PayMent from './payment/index.vue'
import Configuration from './configuration/index.vue'
import Qrcode from './qrcode/index.vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'

const data = reactive({
  code: undefined,
  name: undefined,
  expiredStatus: undefined,
  geoCode: [],
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 100,
  dialogVisible: false,
  billMsgDialogVisible: false,
  payMentDialogVisible: false,
  configurationVisible: false,
  qrcodeVisible: false,
  accountMsg: {
    list: [{ type: 1 }],
    storePayPreference: {},
  },
  isEdit: false,
  shopLists: [],
  deleteRow: [],
  optionsProps: {
    label: 'name',
    children: 'children',
    value: 'code',
    checkStrictly: true,
  },
  authMenus: {},
  loading: false,
})
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)

  //获取门店区域
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    if (res.code === '200') {
      data.shopLists = getTreeData(res.data)
    } else {
      ElMessage.error(res.msg)
    }
  })
  queryShops()
})

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

//重置
const resetQuery = () => {
  data.code = undefined
  data.name = undefined
  data.geoCode = undefined
  data.expiredStatus = undefined
  queryShops()
}

// 获取门店列表数据
const searchLists = () => {
  if (data.authMenus.query) {
    data.loading = true
    let query = {
      code: data.code,
      name: data.name,
      expiredStatus: data.expiredStatus,
      pageNum: data.currentPage,
      pageSize: data.pageSize,
    }
    if (Array.isArray(toRaw(data.geoCode))) {
      query.geoCode = data.geoCode[data.geoCode.length - 1]
    }

    API.getStoreListPages(query).then(res => {
      if (res.code === '200') {
        data.tableData = res.data.records
        data.total = res.data.totalRecords
        data.loading = false
      } else {
        ElMessage.error(res.msg)
        data.loading = false
      }
    })
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
const queryShops = () => {
  if (data.authMenus.query) {
    data.currentPage = 1
    searchLists()
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//展示和修改数据
const showAndEdit = () => {
  data.isEdit = true
  data.dialogVisible = true
}

//展示不修改数据
const showNotEdit = () => {
  data.isEdit = false
  data.dialogVisible = true
}

//添加
const addShop = () => {
  data.accountMsg = {
    list: [{ type: 1, name: '', bank: '', account: '' }],
    storePayPreference: {},
    storeStoreType: [],
    diguantongEnable: 0,
    state: 1,
    noEdit: false,
  }
  showAndEdit()
}
const showRow = rowMsg => {
  API.getStoreStoreByCode({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      data.accountMsg = {
        ...res.data,
        code: res.data.code.slice(1),
        fullCode: res.data.code,
        noEdit: true,
        storeStoreType: res.data.storeStoreType || [],
      }
      showNotEdit()
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const editRow = rowMsg => {
  API.getStoreStoreByCode({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.list.length === 0) {
        res.data.list = [{ type: 1, name: '', bank: '', account: '' }]
      }
      data.accountMsg = {
        ...res.data,
        code: res.data.code.slice(1),
        fullCode: res.data.code,
        noEdit: true,
        storeStoreType: res.data.storeStoreType || [],
        diguantongEnable: res.data.diguantongEnable || 0,
      }
      showAndEdit()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const editBillMsgRow = rowMsg => {
  API.getStorePayPreference({ storeCode: rowMsg.code }).then(res => {
    if (res.code === '200') {
      data.accountMsg = {
        ...res.data,
        storeCode: rowMsg.code,
        isAdd: res.data?.merchantNo ? false : true,
      }
      data.billMsgDialogVisible = true
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const startOrStop = rowMsg => {
  API.modifyStoreStore({ ...rowMsg, code: rowMsg.code, state: rowMsg.state === 1 ? 0 : 1 }).then(res => {
    if (res.code === '200') {
      ElMessage.success('更新成功')
      searchLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showRow(rowMsg)
      break
    case 2:
      editRow(rowMsg)
      break
    case 3:
      startOrStop(rowMsg)
      break
    case 4:
      editBillMsgRow(rowMsg)
      break
    case 5:
      handlePayMent(rowMsg)
      break
    case 6:
      handleConfiguration(rowMsg)
      break
    case 7:
      handleQrcode(rowMsg)
      break
  }
}
const handleConfiguration = rowMsg => {
  data.accountMsg = { ...rowMsg }
  data.configurationVisible = true
}

const handleQrcode = rowMsg => {
  data.accountMsg = { ...rowMsg }
  data.qrcodeVisible = true
}

const handlePayMent = rowMsg => {
  data.accountMsg = {
    ...rowMsg,
    isExpired: rowMsg.expiredTime ? 1 : 0,
    feeAmount: rowMsg.expiredTime ? 15000 : 20000,
    defaultValue: rowMsg.expiredTime ? rowMsg.expiredTime : rowMsg.onlineTime || new Date(),
  }
  data.payMentDialogVisible = true
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    searchLists()
  }
}

const updateBillMsgDialogVisible = ({ update, search }) => {
  data.billMsgDialogVisible = update
  if (search) {
    searchLists()
  }
}

const updatePayMentDialogVisible = ({ update, search }) => {
  data.payMentDialogVisible = update
  if (search) {
    searchLists()
  }
}
const updateConfigurationVisible = ({ update, search }) => {
  data.configurationVisible = update
  if (search) {
    searchLists()
  }
}

const updateQrcodeVisible = ({ update, search }) => {
  data.qrcodeVisible = update
  if (search) {
    searchLists()
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists()
}
//删除门店
const deleteRow = rowMsg => {
  ElMessageBox.confirm('确认删除当前所选门店吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      API.deleteStoreMsg(rowMsg.code).then(res => {
        if (res.code === '200') {
          ElMessage.success('删除成功')
          searchLists()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '删除失败',
      })
    })
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      width: 200px;
      margin-left: 20px;
      margin-right: 10px;
      text-align: right;
    }

    .input-with-select {
      width: 340px;
    }

    .input-search-tel {
      width: 300px;
    }

    .search-button {
      margin-left: 20px;
      width: 144px;
      height: 25px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 10px;
    margin-right: 20px;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
